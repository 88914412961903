import React from 'react';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import * as Yup from 'yup';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    form,
    inputs,
    innerInputs,
    privacyInfo,
    checkboxContainer,
    highlight,
    label,
    button,
} from './consultation-form.module.scss';
import { postConsultation } from '../../api/consultation';

import FormikForm from '../hoc/formik-form';
import Input from '../atoms/form/input';
import Markdown from '../hoc/markdown';
import Button from '../atoms/button';
import CheckboxGroup from '../atoms/checkbox-group';
import Checkbox from '../atoms/form/checkbox';

export interface IConsultationFormProps {
    className?: string;
    onSuccess?(helpers: FormikHelpers<IConsultationFormValues>): any;
    onError?(helpers: FormikHelpers<IConsultationFormValues>): any;
    onLoading?(helpers: FormikHelpers<IConsultationFormValues>): any;
}

type TConsultationFormOptions = string[];

interface IConsultationFormValues {
    name: string;
    surname: string;
    text?: string;
    phone: string;
    email: string;
    consent: boolean;
    options: TConsultationFormOptions;
}

const initialValues: IConsultationFormValues = {
    name: '',
    surname: '',
    text: '',
    phone: '',
    email: '',
    options: [],
    consent: false,
};

const ConsultationForm: React.FC<IConsultationFormProps> = ({
    className = '',
    onSuccess = () => {},
    onError = () => {},
    onLoading = () => {},
}) => {
    const { t } = useI18next();

    const handleSubmit = (
        values: FormikValues,
        helpers: FormikHelpers<IConsultationFormValues>
    ) => {
        onLoading(helpers);

        postConsultation({
            name: values.name,
            surname: values.surname,
            text: values.text,
            phone: values.phone,
            email: values.email,
            isEmail: values.options.includes('isEmail') ? 1 : 0,
            isPhone: values.options.includes('isPhone') ? 1 : 0,
            isMeeting: values.options.includes('isMeeting') ? 1 : 0,
        })
            .then(() => {
                onSuccess(helpers);
            })
            .catch(() => {
                onError(helpers);
            });
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={getValidationSchema(t)}
        >
            {(formik) => {
                const isPhone = formik.values.options.includes('isPhone');
                const isMeeting = formik.values.options.includes('isMeeting');
                const isEmail = formik.values.options.includes('isEmail');

                return (
                    <FormikForm className={`${form} ${className}`} formik={formik}>
                        <div className={inputs}>
                            <Input
                                name="text"
                                as="textarea"
                                placeholder={t('form.consultation.placeholder.text')}
                            />
                            <Input
                                name="name"
                                as="text"
                                placeholder={t('form.consultation.placeholder.name')}
                            />
                            <Input
                                name="surname"
                                as="text"
                                placeholder={t('form.consultation.placeholder.surname')}
                            />
                            <p className={label}>{t('form.consultation.options.label')}</p>
                            <CheckboxGroup
                                name="options"
                                options={[
                                    {
                                        value: 'isEmail',
                                        label: t('form.consultation.placeholder.email'),
                                    },
                                    {
                                        value: 'isPhone',
                                        label: t('form.consultation.placeholder.phone'),
                                    },
                                    {
                                        value: 'isMeeting',
                                        label: t('form.consultation.placeholder.meeting'),
                                    },
                                ]}
                                className={checkboxContainer}
                                styleOfCheckbox={'radio'}
                            />
                            <div className={innerInputs}>
                                <Input
                                    className={isEmail || isMeeting ? highlight : ''}
                                    name="email"
                                    type="email"
                                    placeholder={t('form.placeholder.email')}
                                />
                                <Input
                                    className={isPhone ? highlight : ''}
                                    name="phone"
                                    type="tel"
                                    placeholder={t('form.placeholder.phone')}
                                />
                            </div>
                            <Checkbox name={'consent'} text={t('form.consent')} />
                        </div>

                        <Button
                            text={t('form.consultation.button.send')}
                            className={button}
                            type={'submit'}
                        />
                        <Markdown className={privacyInfo}>{t('form.privacyInfo')}</Markdown>
                    </FormikForm>
                );
            }}
        </Formik>
    );
};

function getValidationSchema(t: ReturnType<typeof useI18next>['t']) {
    return Yup.object({
        text: Yup.string(),
        name: Yup.string().required(t('form.consultation.error.name.required')),
        surname: Yup.string().required(t('form.consultation.error.surname.required')),
        options: Yup.array().min(1, t('form.consultation.options')),
        email: Yup.string().when('options', {
            is: (options: TConsultationFormOptions) =>
                options.includes('isEmail') || options.includes('isMeeting'),
            then: Yup.string()
                .email(t('form.error.email.format'))
                .required(t('form.error.email.required')),
        }),
        phone: Yup.string().when('options', {
            is: (options: TConsultationFormOptions) => options.includes('isPhone'),
            then: Yup.string().required(t('form.error.phone.required')),
        }),
        consent: Yup.boolean().oneOf([true], t('form.error.consent.required')),
    });
}

export default ConsultationForm;
