import React from 'react';
import { ErrorMessage } from 'formik';

import { container, errorBox } from './checkbox-group.module.scss';

import Error from './form/error';
import Checkbox, { TStyleOfCheckbox } from './form/checkbox';

interface ICheckboxGroupOptions {
    value: string;
    label: string;
}

export interface ICheckboxGroupProps {
    className?: string;
    outerClassName?: string;
    checkboxClassName?: string;
    singleChoice?: boolean;
    name: string;
    options: ICheckboxGroupOptions[];
    styleOfCheckbox?: TStyleOfCheckbox;
}

const CheckboxGroup: React.FC<ICheckboxGroupProps> = ({
    className = '',
    outerClassName = '',
    checkboxClassName = '',
    singleChoice = false,
    name,
    options,
    styleOfCheckbox = 'checkbox',
}) => {
    return (
        <div className={`${container} ${outerClassName}`}>
            <ErrorMessage name={name} className={errorBox} component={Error} />
            <div className={className}>
                {options.map((option, index) => {
                    return (
                        <Checkbox
                            key={`checkbox-${name}-${index}`}
                            name={name}
                            text={option.label}
                            value={option.value}
                            singleChoice={singleChoice}
                            className={checkboxClassName}
                            styleOfCheckbox={styleOfCheckbox}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default CheckboxGroup;
