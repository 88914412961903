import axios from 'axios';
import endpoints from '../config/endpoints';

const API_URL = process.env.API_URL;

interface IConsultationData {
    name: string;
    surname: string;
    text?: string;
    phone: string;
    email: string;
    isEmail: number;
    isPhone: number;
    isMeeting: number;
}

export function postConsultation(data: IConsultationData): Promise<any> {
    return axios.post(endpoints({ API_URL }).consultationApplications, data, {
        withCredentials: true,
    });
}
