import React from 'react';
import { graphql, navigate } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { layout, title as titleClass, subtitle } from './consultation-page.module.scss';
import { translationKeys } from '../config/translation-keys';
import { statusMap } from '../config/status-map';
import { IConsultationFormProps } from '../components/organisms/consultation-form';
import { useTranslation } from '../hooks/use-translation';
import { useModalContext } from '../contexts/modal-context';

import ContentLayout from '../layouts/content-layout';
import ConsultationForm from '../components/organisms/consultation-form';

export interface IConsultationPageTranslation {
    title: string;
    lead: string;
}

export interface IConsultationPage {
    consultation: {
        translations: {
            [key: string]: IConsultationPageTranslation;
        };
    };
}

interface IConsultationPageProps {
    readonly data: IConsultationPage;
}

const ConsultationPage: React.FC<IConsultationPageProps> = ({ data }) => {
    const { t } = useI18next();
    const { addModal } = useModalContext();
    const { title, lead } = useTranslation(data.consultation, translationKeys.consultationPage);

    const handleLoading: IConsultationFormProps['onLoading'] = (helpers) => {
        helpers.setStatus(statusMap.loading);
        addModal({
            type: 'loading',
        });
    };

    const handleSuccess: IConsultationFormProps['onSuccess'] = (helpers) => {
        helpers.setStatus(statusMap.idle);
        return navigate('/konsultacja/podziekowanie/');
    };

    const handleError: IConsultationFormProps['onError'] = (helpers) => {
        helpers.setStatus(statusMap.error);
        addModal({
            type: 'error',
            message: t('form.contact.error'),
        });
    };

    return (
        <ContentLayout SEOProps={{ title }} className={layout}>
            <div>
                <h1 className={titleClass}>{title}</h1>
                <h2 className={subtitle}>{lead}</h2>
            </div>
            <ConsultationForm
                onLoading={handleLoading}
                onSuccess={handleSuccess}
                onError={handleError}
            />
        </ContentLayout>
    );
};

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        consultation {
            translations {
                pl {
                    title
                    lead
                }
            }
        }
    }
`;

export default ConsultationPage;
